@import "src/config/theme/vars.scss";
//classes are prefixed with '_modal_' to prevent 
//classname classes with mergestyles
._modal_root {
   display: flex;
   justify-content: center;
   max-width: 100%;
   max-height: 100%;
   @include breakpoint($ltxs) {
      height: 100%;
      width: 100%;
   }
}

._modal__modalwrap {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: stretch;
   margin: auto;
   max-height: 100%;
}

._modal_modal-header {
   background-color: $primaryColor;
   height: 60px;
   padding: 0 16px 0px 24px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-bottom: 1px solid #eee;
   color: $offColor;
   flex-shrink: 0;
}

._modal_modal {
   background-color: #fff;
   max-height: 100%;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   min-width: 400px;
   @include breakpoint($ltxs) {
      min-width: 100%;
   }
}

._modal_button-wrap {
   display: flex;
   align-items: center;
   padding-top: 16px;
   justify-content: flex-end;
   margin: 0px -8px;
}

._modal_body {
   padding: 24px;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

._modal_icon {
   padding: 8px;
   cursor: pointer;
   >svg {
      width: 20px;
      height: 20px;
   }
}
