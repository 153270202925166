@import "src/config/theme/vars.scss";
//classes are prefixed with '_cbox_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._cbox_root {
   padding: 0px;
	display: flex;
	user-select: none;
	cursor: pointer;
   vertical-align: middle;
   >input {
      cursor: pointer; // position: absolute;
      opacity: 0;
      margin: 0px;
      display: none;
      &[disabled] {
         cursor: inherit;
      }
   }
   >span {
      vertical-align: middle;
      line-height: 28px;
   }
}

/* On mouse-over, add a grey background color */

._cbox_root:hover input:not([disabled])~._cbox_mark {
   /* background-color: #ccc;*/
}

// input:not([disabled]):focus~._cbox_mark {
//     background-color: #ccc;
// }
