@import "src/config/theme/vars.scss";
// $width: 300px;
@mixin _float {
   position: relative;
   width: 0px !important;
   transition: left, right 0.5s ease;
   overflow: visible;
   >div {
      position: absolute;
   }
   &[position="left"] {
      direction: ltr;
      right: 0px;
      // &:not([open]) {
      //    right: $width;
      // }
   }
   &[position="right"] {
      direction: rtl;
      left: 0px;
      // &:not([open]) {
      //    left: $width;
      // }
   }
}

._root {
   height: 100%;
   // width: $width;
   overflow: hidden;
   transition: width 0.5s ease;
   >div {
      // width: $width;
      max-width: 100vw;
   }
   &:not([open]) {
      width: 0px !important;
   }
   &[position="left"] {
      direction: rtl;
      >div {
         direction: ltr;
      }
   }
   &[position="right"] {
      direction: ltr;
      >div {
         direction: rtl;
      }
   }
   &.sm {
      @include breakpoint($ltsm) {
         @include _float();
      }
   }
   &.md {
      @include breakpoint($ltmd) {
         @include _float();
      }
   }
   &.xs {
      @include breakpoint($ltxs) {
         @include _float();
      }
   }
   &.lg {
      @include breakpoint($ltlg) {
         @include _float();
      }
   }
   &.always {
      @include _float();
   }
}

._drawer {
   height: 100%;
}

._close-area {
   display: none;
   position: absolute;
   top: 0px;
   left: 0px;
   right: 0px;
   bottom: 0px;
   &.sm {
      @include breakpoint($ltsm) {
         display: block;
      }
   }
   &.md {
      @include breakpoint($ltmd) {
         display: block;
      }
   }
   &.xs {
      @include breakpoint($ltxs) {
         display: block;
      }
   }
   &.lg {
      @include breakpoint($ltlg) {
         display: block;
      }
   }
   &.always {
      display: block;
   }
}
