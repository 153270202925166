$primaryColor: #E5091C;
$offColor: #FFFFFF;
$secondaryColor:#000812;
$secondaryOffColor: #FFFFFF;
$shadowColor: #D0D6DC;

// export these vars to javascript via webpack
:export {
   primaryColor: $primaryColor;
   offColor: $offColor;
   secondaryColor: $secondaryColor;
   shadowColor: $shadowColor;
   secondaryOffColor: $secondaryOffColor;
}