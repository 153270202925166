@import "src/config/theme/vars.scss";
//classes are prefixed to prevent name clashes with 
// merging styles.scss files, from the consumer pages
.__markimage {
	border: 1px solid #E5E9ED;
}

._clearBtnContainer {
   position: absolute;
   top: 0;
   right: 0;
   display: flex;
   justify-content: flex-end;
   >button {
      border: 1px solid #F8FAFB;
   }
}

