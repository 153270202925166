@import "src/config/theme/vars.scss";
.root {
  height: 100%;
	background-color: #37415E;
	z-index: 5;
}

button.btn{
	width: 100%;
	margin: 0px;
	background-color: inherit;
	cursor: pointer;
}
