@import "src/config/theme/vars.scss";
._root {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: auto;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   align-self: stretch;
   position: relative;
}

._bar {
   background: linear-gradient($primaryColor, #B797AE);
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   border-radius: 4px;
   height: 8px;
}

._knob {
   border-radius: 35px;
   background-color: #fff;
   box-shadow: 1px 1px 5px #CDC6C9;
   position: absolute;
   top: -10px;
   @include ifIE11() {
      top: 4px;
   }
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 4px 8px;
   color: $primaryColor;
	min-width: 45px;
	cursor: pointer;
}

._input {
   width: 50px;
   border: 0px;
   color: currentColor;
   text-align: center;
}
