@import "src/config/theme/vars.scss";
//classes are prefixed  to prevent name clashes with 
// merging styles.scss files, from the consumer pages

// $size: 34px;

._switch_root {
   display: flex;
	user-select: none;
   align-items: center;
}

._switch_text{
	margin-left: 12px;
}
._switch_text-left{
	margin-right: 12px;
}

._switch_switch {
   position: relative;
	display: inline-block;
}

/* Hide default HTML checkbox */

._switch_switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

/* The slider */

._switch_slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   transition: .4s;
}

// ._switch_slider:before {
//    position: absolute;
// 	content: "";
//    height: 26px;
//    width: 26px;
//    left: 4px;
//    bottom: 4px;
//    background-color: white;
//    transition: .4s;
// }

input:checked+._switch_slider {
   background-color: $primaryColor;
}

input:focus+._switch_slider {
   box-shadow: 0 0 1px $primaryColor;
}

input:checked+._switch_slider> span {
   // -webkit-transform: translateX(34px);
   // -ms-transform: translateX(34px);
   // transform: translateX(34px);
}

._switch_slider> span{
	position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
	transition: .4s;
	&[haschildren="false"]{
		background-color: #fff;
		// height: $size;
		// width: $size;
		// border-radius: $size;
		box-shadow: 1px 1px 3px $shadowColor;
	}
}



