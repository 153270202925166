@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._root {
   user-select: none;
   min-width: 150px;
   display: flex;
   justify-content: space-between;
   align-items: center;
	cursor: pointer;
	>[selectchevron]{
		// for some reason clicking on another selects icon will not close the open select, so allow click thru svg icon fixes it
		pointer-events: none;
	}
}

.close-area {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   display: none;
   &[open] {
      display: inherit;
   }
}

._menu-wrap {
   background-color: transparent;
   transform: scale(0, 0);
   transform-origin: top left;
   transition: transform 0.05s ease;
   &[open] {
      transform: scale(1, 1);
   }
}

._row {
   background-color: inherit;
   color: inherit;
   display: flex;
   align-items: center;
	justify-content: flex-start;
	cursor: pointer;
   padding: 4px 16px;
   @include breakpoint($ltsm) {
      padding: 16px;
   }
   transition: background-color 0.3s ease;
   min-height: 25px;
   &:not(:last-child) {
      border-bottom: 1px solid #eee;
   }
   &[isfocussed] {
      background-color: #F2F2F2;
      color: #333;
   }
}

._menu {
   padding: 2px;
   background-color: #fff;
   box-shadow: 1px 1px 5px #333;
	display: flex;
   flex-direction: column;
   align-items: stretch;
   margin: 5px 0px;
   border-radius: 1px;
   overflow: auto; -webkit-overflow-scrolling: touch;;
}

.none {
   color: #999;
}

._select-placeholder {
	flex:1; @include ifIE11() {flex: 1 1 auto;}
   color: #999;
}

._select_title{
	flex:1; @include ifIE11() {flex: 1 1 auto;}
}

