@import "src/config/theme/vars.scss";
.root {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   flex-direction: column;
   align-items: center;
   [hide="true"] {
      display: none;
   }
}

.page {
   width: 100%;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   max-width: 1000px;
   background-color: #fff;
   box-shadow: 1px 1px 3px 1px $shadowColor;
   margin-top: 60px;
   @media (max-width: 1000px) {
      margin-top: 0px;
   }
}

.btn {
   font-weight: bold;
   text-transform: uppercase;
   cursor: pointer;
   &[disabled]{
      cursor: not-allowed;
      background-color: #cfcfcf;
   }
}

.header {
   z-index: 1;
   display: flex;
   align-items: center;
   position: sticky;
   top: 0px;
   height: auto;
   background-color: #fff;
   flex-wrap: wrap;
   .btn {
      @include breakpoint($ltsm) {
         display: none;
      }
   }
   .mobile-menu {
      display: none;
      padding: 5px 16px;
      width: 20px;
      height: 20px;
      @include breakpoint($ltsm) {
         display: inherit;
      }
   }
}

.content {
   padding: 16px 60px;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   @include breakpoint($ltsm) {
      padding: 16px 16px;
   }
}

.form-row {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
}

.title {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   font-weight: bold;
   font-size: 16px;
   text-align: center;
}

.center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.section-header {
   padding: 8px 0px;
   font-weight: bold;
   border-bottom: 1px solid #C8C8C8;
   display: flex;
   position: relative;
   width: 100%;
   z-index: 10;
   background-color: white;
   cursor: pointer;
   align-items: center;
   justify-content: space-between;
}

.section-wrapper{
   overflow: hidden;
   display: flex;
   flex-shrink: 1; 
   position: relative;
   flex-direction: column;
   background-color: white;
   width: 100%;
}

.transition-hack{
   position: absolute;
}

.section-sections {
   display: flex;
   width: 100%;
   position: relative;
   flex-direction: column;
   margin-bottom: 24px;
   margin-top: 0px;
   transition: all 0.4s;
   overflow: hidden;
}

.input-field {
   margin-bottom: 12px;
}

.padding16 {
   padding: 16px;
}

.input-error {
   color: red;
   font-size: 14px;
}

.field-header {
   padding: 8px 0px;
}

.field-sections {
   display: flex;
   flex-direction: column;
   margin: 18px 0px;
}

.black {
   background-color: #000000;
}

div.field-dropdown-menus {
   padding: 0px;
   box-shadow: none;
   border: 1px solid #E5E9ED;
}

.multichevron {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: center;
   >div {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
   }
}

.flex1 {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.subreference{
	flex:1; @include ifIE11() {flex: 1 1 auto;}
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-100 {
   flex: 1 1 100%;
}

.gpsval {
   margin: 12px 0px;
}

.check-box {
   margin: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.matrix-table {
   width: 100%;
   table-layout: fixed;
   border-collapse: collapse;
   border-spacing: 0px;
   td {
      vertical-align: top;
      @include breakpoint($gtsm) {
         border-top: 1px solid #eee;
      }
      >label {
         //hide label when not in mobile
         display: none;
      }
      @include breakpoint($ltsm) {
         >label {
            flex: 1; @include ifIE11() {flex: 1 1 auto;}
            display: flex;
            align-items: center;
         }
      }
      &[fieldtype="Checkbox"] {
         @include breakpoint($ltsm) {
            //make checkboxes on mobile go inline with the label and make the label flex1 so it takes up the space
            display: flex;
            flex-direction: row;
            >div {
               flex: 0; @include ifIE11() {flex: 0 1 auto;}
            }
         }
         [checkbox] {
            //checkboxes in table never have labels so center them
            justify-content: center;
         }
      }
      [select],
      [multi-select] {
         //remove from selects so they dont overflow
         min-width: initial;
      }
   }
   td,
   th {
      padding: 4px;
      &[mobile] {
         display: none;
      }
      @include breakpoint($ltsm) {
         &[mobile] {
            display: inherit;
         }
         &[desktop] {
            display: none;
         }
      }
   }
   [formlabel] {
      // dont show column label on each cell
      display: none;
   }
   >thead {
      @include breakpoint($ltsm) {
         //hide head when in card mode
         display: none;
      }
   }
   tr {
      @include breakpoint($ltsm) {
         // turn table into flexbox on mobile to make look like cards
         display: flex;
         flex-wrap: wrap;
         padding: 10px;
         background-color: #fff;
         box-shadow: 1px 1px 3px $shadowColor;
         margin: 16px 6px;
         >td:not([operations]) {
            flex: 1 1 100%;
         }
         >td[hasop="false"] {
            display: none;
			}
			>td.card-header {
				justify-content: space-between;
				display: flex;
         }
         justify-content: flex-end;
         &[desktop] {
            display: flex;
         }
      }
   }
   >tbody {
      &[useportal="false"] {
         >tr[mobile] {
            // never show mobile 'portal' rows
            display: none;
         }
      }
      &[useportal="true"] {
         @include breakpoint($ltsm) {
            >tr[mobile] {
               display: flex;
            }
            >tr[desktop] {
               display: none;
            }
         }
      }
   }
}

.repeating-group-table {
   width: 100%;
   table-layout: fixed;
   td {
      vertical-align: top;
      [select],
      [multi-select] {
         //remove from selects so they dont overflow
         min-width: initial;
      }
   }
   [formlabel] {
      @include breakpoint($gtsm) {
         // dont show column label on each cell
         display: none;
      }
   }
   >thead {
      @include breakpoint($ltsm) {
         display: none;
      }
   }
   tr {
      &[mobile] {
         display: none;
      }
      @include breakpoint($ltsm) {
         display: flex;
         flex-wrap: wrap;
         padding: 10px;
         background-color: #fff;
         box-shadow: 1px 1px 3px $shadowColor;
         margin: 16px 6px;
         >td:not([operations]) {
            flex: 1 1 100%;
         }
         >td[hasop="false"] {
            display: none;
         }
         >td.card-header {
				justify-content: space-between;
				display: flex;
         }
         justify-content: flex-end;
         &[mobile] {
            display: flex;
         }
         &[desktop] {
            display: none;
         }
      }
   }
}

.remove-cross {
   vertical-align: top;
   color: #666;
   width: 20px;
   >div {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
   }
   &[mobile] {
      display: none;
   }
   @include breakpoint($ltsm) {
		justify-content: flex-end;
		align-content: center;
      &[mobile] {
         display: flex;
      }
      &[desktop] {
         display: none;
      }
      >svg {
         padding: 8px 16px 8px 8px ;
      }
   }
}

.operation-content {
   background-color: #F7F8FA;
   border: 1px solid #E5E9ED;
   max-width: 100%;
   padding: 8px;
   font: 400 13.3333px Arial;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   @include breakpoint($gtsm) {
      height: 26px;
   }
}

.operation-label {
   flex: 1 1 100%;
   margin-bottom: 6px;
   border-bottom: 1px solid #ccc;
   @include breakpoint($gtsm) {
      display: none;
   }
}

.pre-wrap {
   white-space: pre-wrap;
   word-wrap: break-word;
   margin: 0;
}
