@import "src/config/theme/vars.scss";
//classes are prefixed  to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._imgl_center {
   display: flex;
   justify-content: center;
   align-items: center;
}

._imgl_ {
	height: 100px;
}
