@import "src/config/theme/vars.scss";
.header {
   background-color: #002249;
   box-shadow: 0px 1px 3px #aaa;
   z-index: 2;
}

.header-content {
   flex-direction: row;
   justify-content: center;
   align-items: stretch;
   display: flex;
   height: 70px;
}

.red-bar {
   height: 6px;
   background-color: $primaryColor;
   @include breakpoint($ltsm) {
      display: none;
   }
}

.reload-bar {
   background-color: yellow;
   display: flex;
	justify-content: center;
	font-weight: bold;
   align-items: center;
   height: 60px;
   cursor: pointer;
}

.user-icon-wrap {
   color: $offColor;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 0px 8px 0 5px;
   .user-icon {
      height: 38px;
      width: inherit;
      color: $primaryColor;
   }
}

.username-wrap {
   padding-right:10px;
   font-size:16px;
   font-family:Roboto;
   color:white;
}

.main {
   flex: 2; @include ifIE11() {flex: 2 1 auto;}
   display: flex;
   align-items: stretch;
   justify-content: flex-start;
}

.tabs {
   display: flex;
   align-items: stretch;
   div {
      align-items: stretch;
      display: flex;
   }
}

.namewrap {
   cursor:pointer;
   display: flex;
   align-items: center;
   justify-content: center;
}

.username {
   color: #E55387
}

.logo {
   padding: 0px 24px;
   display: flex;
   justify-content: center;
   align-items: center;
   >img {
      height: auto;
      width:135px;
      margin: 0px 12px;
   }
   @include breakpoint($ltsm) {
		margin: 0px;
		padding: 0px;
   }
}

.tab-icon {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >svg {
      width: inherit !important;
      height: 30px;
      margin: auto;
   }
}

.menubtn {
   >img {
      height: 25px;
      margin: 0px 12px;
   }
   cursor:pointer;
   height: 70px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size:30px;
   color:white;
   padding:0 15px;
}
