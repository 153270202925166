@import "src/config/theme/vars.scss";
//classes are prefixed with '_img_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._img_root {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-direction: column;
}

._img_preview {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   overflow: auto; -webkit-overflow-scrolling: touch;;
   width: 100%;
}


._img_ {
	height: 100px;
}

._img_center {
   display: flex;
   justify-content: center;
   align-items: center;
}

._img_margin {
	margin-right: 4px;
	cursor: pointer;
}
