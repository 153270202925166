@import "src/config/theme/vars.scss";
//classes are prefixed to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._impg_modal {
   height: 100%;
   width: 100%;
   user-select: none;
}

._imgp_root {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-direction: column;
   height: 100%;
}

._imp_content-wrap {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   width: 100%;
   display: flex;
   align-items: center;
   ._imgp_content {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
      height: 100%;
      position: relative;
      >._imgp_main {
         max-height: 100%;
         max-width: 100%;
         width: auto;
         height: auto;
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         margin: auto;
      }
      ._impg_name {
         color: white;
         font-size: 24px;
         text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
         position: absolute;
         top: 20px;
         left: 10px;
         right: 10px;
         text-align: center;
			vertical-align: center;
			word-break: break-all;
			word-wrap: break-word;
      }
   }
}

._imgp_sides,
._imgp_sides-right {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 8px;
   svg {
      cursor: pointer;
   }
}

._imgp_sides-right {
   flex-direction: column;
   ._impg_buttons {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px;
		>svg{
			margin-bottom: 8px;
		}
	}
}

._imgp_preview {
   display: flex;
   flex-shrink: 0;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   width: 100%;
   padding: 8px;
}

._imgp_ {
   height: 50px;
   margin-right: 10px;
   cursor: pointer;
   &:last-child {
      margin-right: auto;
   }
   &:first-child {
      margin-left: auto;
   }
}

._imgp_center {
   display: flex;
   justify-content: center;
   align-items: center;
}

div._imgp_modal {
   background-color: rgb(0, 0, 0);
}

._imgp_upload {
   flex-shrink: 0;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   max-height: 100px;
   max-width: 300px;
}
