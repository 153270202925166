@import "src/config/theme/vars.scss";
@mixin _stdinput {
   padding: 8px;
   height: 26px;
}

.select {
   border: 1px solid #E5E9ED;
   padding: 8px;
   margin-right: 16px;
}

._form_input {
   background-color: #F7F8FA;
   border: 1px solid #E5E9ED;
   max-width: 100%;
   @include _stdinput;
}

textarea._form_input {
   //multiline input
   height: 76px;
   resize: none;
}

.input-group {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: stretch;
   flex-direction: column;
}

._input-error {
   color: red;
   font-size: 14px;
}

.slider {
   width: 600px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 36px;
}

.space-between {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.radio-text {
   color: #fff;
}

._form_select {
   @include _stdinput;
   border: 1px solid #E5E9ED;
}

._calandar-wrap {
   display: flex;
   flex-direction: column;
   user-select: none;
   align-items: stretch; // border: 1px solid $primaryColor;
}

._calandar-shrink {
   overflow: hidden;
   position: absolute;
   top: -100%;
   max-width: 100%;
   transform: scale(0, 0);
   transition: transform 0.05s ease;
   &[open] {
      transform: scale(1, 1);
   }
}

.__calacnder-class {
   margin: auto;
   max-width: 100%;
}

.calander-root {
   border: 1px solid #E5E9ED;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
}

.close-area {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   display: none;
   &[open] {
      display: inherit;
   }
}

.__flex {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.calandar-header {
   padding: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   >svg {
      margin: 0 14px 0 8px;
   }
}

.__input-sub-spacing {
   margin-bottom: 4px;
}

:global {
   .__input-signature {
      border: 1px solid #E5E9ED;
   }
}

.__signature-root {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
}

._fblContainer {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;
   @include breakpoint($ltxs) {
      flex-direction: column;
      align-items: stretch;
      flex-wrap: nowrap;
   }
}

._clearBtnContainer {
   position: absolute;
   top: 0;
   right: 0;
   display: flex;
   justify-content: flex-end;
   >button {
      border: 1px solid #F8FAFB;
   }
}

.__typohead {
   background-color: #F7F8FA;
   border: 1px solid #E5E9ED;
   max-width: 100%;
   @include _stdinput;
   >input {
      border: none;
      background-color: inherit;
      &:focus {
         outline: none;
      }
   }
}

.__markimage-preview {
	padding: 1px;
}
