@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._root {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
}

._radio {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   padding: 4px;
   padding-left: 2px;
   &:focus {
      >._radio-button {
         background-color: rgba(119, 118, 118, 0.219);
      }
   }
}

._radio-button {
   background-color: $offColor;
   height: 20px;
   width: 20px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 1px 1px 3px $shadowColor inset;
   &[isselected] {
      >div {
         background-color: $secondaryColor;
      }
   }
   >div {
      background-color: transparent;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      transition: background-color 0.15s ease;
   }
}

._radio-title {
   margin-left: 8px;
}
