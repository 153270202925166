@import "src/config/theme/vars.scss";
.___modal__background {
   display: none;
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0px;
   bottom: 0px;
   background-color: rgba(0, 0, 0, 0.3);
   justify-content: center;
   align-items: center;
   height: 100%;
	width: 100%;
	overflow: hidden;
	flex-direction: column;
   &[open] {
      display: flex;
   }
}

.__modal-root {
   max-height: 100%;
	max-width: 100%;
   >* {
      max-height: 100%;
      max-width: 100%;
   }
}
