.status-wrapper{
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: auto;
    height: 50px;
    background-color: #002249;
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 2px;
    margin-right: 20px;
}

.status-button{
    background-color: #E5091C;
    color: white;
    height: 30px;
    min-width: 30px;
    margin: 10px;
    display: flex;
    padding: 5px 10px;
    align-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px;
    &:hover{
        background-color: rgba( 229, 9, 28, 0.8);
    }
}

.spinner-wrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    padding-top: 120px;
}
