@import "src/config/theme/vars.scss";

//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._template-root {
   padding: 4px;
   vertical-align: top;
}

._root {
   user-select: none;
   min-width: 150px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   cursor: pointer;

   >svg {
      margin: 0px 14px 0px 8px;
   }
}

.typeahead-underlined-text {
   border-bottom: 3px solid red;
}

._popup-root {
   background-color: #fff;
   box-shadow: 1px 1px 3px $shadowColor;
   box-shadow: 1px 1px 3px var(--shadow-color);
   overflow: auto; -webkit-overflow-scrolling: touch;;
	max-height: 300px !important;

   @include breakpoint($ltsm) {
      max-height: 100px;
   }
}

._popup-row {
   padding: 4px;

   &[isfocussed="true"] {
      background-color: #eee;
   }
}

._input {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   border: none;
   box-sizing: border-box;
   min-width: 150px;
}

.close-area {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   display: none;

   &[open] {
      display: inherit;
   }
}
